/*------------------------*/
/*---Register-Login----*/
/*------------------------*/

.register-login {
  width: 20%;
  margin-left: 40%;
  margin-top: 50px;
}
.forgotten-password {
  width: 30%;
  margin-left: 35%;
  margin-top: 50px;
}
.forgotenPasswordLable {
  text-align: center;
}
/*------------------------*/
/*---Restaurant----*/
/*------------------------*/

.restaurant-container {
  width: 80%;
  margin: 20px 10%;
}

.restaurant-detail {
  border: 1px solid gray;
  border-radius: 5px;
  width: 80%;
  margin: 20px 10%;
}
.restaurant-search {
  width: 80%;
  margin: 0 auto;
}
.restaurant-button-add {
  margin-bottom: 10px;
}
.restaurant-add-name {
  margin-bottom: 10px;
}
.rd-name {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.rd-desc {
  font-size: 18px;
  color: gray;
}
.rd-desc-title {
  font-weight: 500;
}
.rd-shop-icon {
  font-size: 50px;
  margin: 20px 8px;
  color: lightblue;
}
.rd-button {
  margin: 5px;
  width: 100px;
}
.rd-button-user {
  margin: 25px 5px;
  width: 100px;
}
.rd-button-icon-edit {
  margin: 5px;
  color: green;
  font-size: 30px;
  cursor: pointer;
}
.rd-button-icon-delete {
  margin: 5px;
  color: red;
  font-size: 30px;
  cursor: pointer;
}
.rd-button-container {
  padding: 5px;
  text-align: center;
}
.rd-edit-name {
  margin-top: 10px;
}
.rd-edit-desc {
  margin: 10px 0;
}

/*------------------------*/
/*---Menu----*/
/*------------------------*/

.menu-container {
  width: 80%;
  margin: 20px 10%;
}

.meal-detail {
  border: 1px solid gray;
  border-radius: 5px;
  width: 80%;
  margin: 20px 10%;
}
.menu-search {
  width: 80%;
  margin: 0 auto;
}
.menu-button-add {
  margin-bottom: 10px;
}
.menu-button-cart {
  margin-bottom: 10px;
  width: 200px;
  float: right;
}
.menu-remove-order-meal {
  color: red;
  cursor: pointer;
}
.menu-totalsum {
  margin-right: 30px;
  float: right;
}
.menu-temp-msg {
  margin-top: 5px;
}
.meal-add-desc {
  margin-bottom: 10px;
}
.meal-add-name {
  margin-bottom: 10px;
}
.md-name {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}
.md-desc {
  font-size: 18px;
  color: gray;
}
.md-price {
  font-size: 18px;
  color: gray;
  margin-top: 15%;
}
.md-desc-title {
  font-weight: 500;
}
.md-shop-icon {
  font-size: 40px;
  margin: 12px 8px;
  color: lightblue;
}
.md-button {
  margin: 5px;
  width: 100px;
}
.md-button-icon-edit {
  margin: 5px;
  color: green;
  font-size: 30px;
  cursor: pointer;
}
.md-button-icon-delete {
  margin: 5px;
  color: red;
  font-size: 30px;
  cursor: pointer;
}
.md-button-container {
  margin-top: 10px;
  padding: 5px;
  text-align: center;
}
.md-edit-name {
  margin-top: 10px;
}
.md-edit-desc {
  margin: 10px 0;
}
.md-icon {
  font-size: 18px;
}
.md-quantity {
  width: 130px;
}
.md-add-to-cart-btn {
  margin-top: 5px;
  width: 130px;
}

/*------------------------*/
/*---Order----*/
/*------------------------*/

.order-container {
  width: 80%;
  margin: 20px 10%;
}
.order-detail {
  border: 1px solid gray;
  border-radius: 5px;
  width: 80%;
  margin: 20px 10%;
}
.order-search {
  width: 80%;
  margin: 0 auto;
}
.order-active-btn {
  margin-bottom: 10px;
  width: 100%;
}
.order-temp-msg {
  margin-top: 5px;
}
.od-field {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
}
.od-field-title {
  font-size: 16px;
  font-weight: 500;
}
.od-field-value {
  font-size: 18px;
  font-style: italic;
}
.od-shop-icon {
  font-size: 40px;
  margin: 12px 8px;
  color: lightblue;
}
.od-button {
  margin: 5px;
  width: 170px;
}
.od-button-container {
  text-align: center;
}
.od-icon {
  font-size: 18px;
}
.oi-container {
  width: 80%;
  margin: 20px 10%;
}
.oi-details {
  border: 1px solid gray;
  border-radius: 5px;
}
.oi-field {
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
}
.oi-field-title {
  font-size: 16px;
  font-weight: 500;
}
.oi-field-value {
  font-size: 20px;
  font-style: italic;
}
.oi-shop-icon {
  font-size: 40px;
  margin: 12px 8px;
  color: lightblue;
}
.oi-button {
  width: 145px;
  margin: 5px;
}
.oi-shop-icon-small {
  font-size: 30px;
  margin: 12px 8px;
  color: lightblue;
}
.oi-title-section {
  font-weight: 500;
  font-size: 18px;
  margin: 5px 20px;
}
.oi-field-small {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}
.oi-field-title-small {
  font-size: 14px;
  font-weight: 500;
}
.oi-field-value-small {
  font-size: 16px;
  font-style: italic;
}
.oi-ordered-meals-section {
  margin-bottom: 10px;
}
.oi-ordered-history-section {
  margin-bottom: 10px;
}
.oi-left-section {
  border-right: 1px solid lightgray;
}
/*------------------------*/
/*---MOBILE----*/
/*------------------------*/

@media (min-width: 320px) and (max-width: 480px) {
  .register-login {
    width: 100%;
    margin-left: auto;
    margin-top: auto;
  }
  .restaurant-detail {
    border: 1px solid gray;
    border-radius: 5px;
    width: auto;
    margin: auto;
  }
  .forgotten-password {
    width: 100%;
    margin-left: auto;
    margin-top: auto;
  }
}
